.product-filter-container {

  /* Basic styles for the filter button */
  .product-filter-button {
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px
  }

  .product-filter-button:hover {
    background-color: #202122;
  }

  /* Side menu container */
  .product-filter-side-menu {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    background-color: #f4f4f4;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease;
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
    width: 30vh;

    /* Header inside the side menu */
    .product-filter-menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;

      .product-filter-menu-header h2 {
        margin: 0;
        font-size: 20px;
      }

      /* Close button styles */
      .close-button {
        font-size: 24px;
        cursor: pointer;
      }

      .close-button:hover {
        color: #202122;
      }
    }

    /* Menu content */
    .menu-content {
      margin-top: 25px;

      .menu-content p {
        font-size: 16px;
        margin-bottom: 15px;
      }

      /* Input fields styling */
      .input-row {
        display: flex;
        gap: 10px;
      }

      .input-group {
        flex: 1;
        margin-bottom: 0;
      }

      .input-group input {
        width: 130px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        outline: none;
      }

      .input-group input:focus {
        border-color: black;
      }

      .input-group input::placeholder {
        color: #616161;
      }
    }

    /* Dropdown styling */
    .product-filter-dropdown {
      margin-top: 20px;

      .product-filter-dropdown-button {
        background: none;
        border: none;
        color: #000;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        position: relative;
        border-bottom: solid 1px black;
      }

      /* Arrow icon styling */
      .product-filter-dropdown-button .arrow {
        margin-left: auto;
      }

      /* Dropdown menu */
      .product-filter-dropdown-menu {
        margin-top: 10px;
        padding: 10px;
        border-radius: 4px;
      }

      .product-filter-dropdown-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      .product-filter-dropdown-item label {
        margin-left: 8px;
        cursor: pointer;
      }

      .product-filter-dropdown-item input[type="checkbox"] {
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #000;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
      }

      /* Add a black checkmark when the checkbox is checked */
      .product-filter-dropdown-item input[type="checkbox"]:checked {
        background-color: #000;
      }

      /* Create a custom checkmark */
      .product-filter-dropdown-item input[type="checkbox"]:checked::after {
        content: '';
        position: absolute;
        left: 4px;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    .clear-button {
      background-color: black;
      color: white;
      border: none;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
      text-transform: uppercase;
      position: sticky;
      bottom: 0;
      margin-top: 20px;
    }

    .clear-button:hover {
      background-color: #202122;
    }
  }

  /* Open state for the side menu */
  .product-filter-side-menu.open {
    left: 0;
  }

  /* Overlay background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}